<template>
    <b-row>

        <b-col
                cols="12"
                md="12"
                class=" mb-1"
        >

            <div class="pb-2 mt-1 table-top-toolbar">

                <b-row>

                    <b-col
                            cols="12"
                            md="6"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <v-select
                                v-model="tableData.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                    </b-col>

                    <!-- Search -->

                </b-row>

            </div>

            <div class="table-container-wrap">
                <b-table
                        :ref="PREFIX+'_TABLE'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                >

                    <template #head()="data">
                        <span class="text-black-50">{{ $t(data.label).ucFirst() }}</span>
                    </template>

                    <template #cell(is_accepted)="data">

                        <b-form-checkbox
                                :checked="data.item.is_accepted == 1"
                                :id="'item-'+data.item.id"
                                true-value="1"
                                false-value="0"
                                @change="changeAcception($event,data.item )"
                        >
                            <label :for="'item-'+data.item.id"> {{ (data.item.is_accepted == 1)? $t('label_yes') :
                                $t('label_no')}}</label>
                        </b-form-checkbox>


                    </template>


                    <template #cell(content)="data">

                        <partial-text :text="data.item.content"></partial-text>

                    </template>


                    <template #cell(related_docs_data)="data">


                        <div class="d-flex align-items-center">
                            <!--v-model="files"-->
                            <file-input
                                    v-if="$can('edit', MODULE_PREFIX+ '.tab_rodo')"
                                    :ref="'file-input-'+data.item.id"
                                    @files-updated="uploadFiles($event,data.item.id)"
                                    class="file--uploader"
                                    :max-files-count='10'
                                    :required="false"
                                    :multiple="true"
                                    allowed-extensions='*'
                                    :max-file-size='15'
                                    :url="''"
                            >
                                <template #uploadBody>
                                    <!--<img src="http://100dayscss.com/codepen/upload.svg" class="mr-1">-->
                                    {{$t('label_add_file')}}
                                </template>
                            </file-input>

                            <div v-show="$can('view', 'contact.tab_rodo') && data.item.documents">
                                <feather-icon class="ml-1" icon="FileIcon" :id="'popover-docs-'+data.item.id"
                                              size="19"/>
                                <b-popover
                                        class="bg-info"
                                        :target="'popover-docs-'+data.item.id"
                                        triggers="hover"
                                        custom-class="file-listing"
                                        placement="bottom"
                                        boundary="window"
                                        variant="secondary"
                                >
                                    <template #title>
                                        <span>{{$t('label_documents')}}</span>
                                    </template>
                                    <div class="tooltip-content">
                                        <div v-for="doc in data.item.documents">
                                            <a
                                                    :href="$base_url + MODULE_PREFIX+ '_' +PREFIX+'/download/' + doc.id + '?hash=' + doc.hash"
                                                    target="_blank" class="text-success ">{{doc.display_name}}</a>
                                        </div>
                                    </div>
                                </b-popover>

                            </div>
                        </div>

                    </template>


                </b-table>
            </div>
            <!--pagination-->
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="tableTotal"
                                :per-page="tableData.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-col>

    </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BFormFile, BButton, BPopover

    } from 'bootstrap-vue'

    import VueUploadComponent from 'vue-upload-component'

    import vSelect from 'vue-select'
    import fileInput from '@/views/components/fileUploadInput'
    import partialText from '@/views/components/partialText'

    import { CONTACT_PREFIX as MODULE_PREFIX, RODO_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BFormFile, BButton, BPopover,
            vSelect, VueUploadComponent, fileInput, partialText
        },
        props: ['moduleItem'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                module_id: 0,

                columnsDefs: [
                    {label: '№', key: 'id', thClass: 'hidden', tdClass: 'hidden'},
                    {label: 'label_sonsensus', key: 'is_accepted'},
                    {label: 'label_name', key: 'name', sortable: false},
                    {label: 'label_clause', key: 'content', sortable: false},
                    {label: 'label_documents', key: 'related_docs_data'},
                    {label: 'label_ip_address', key: 'ip_address'},
                ],
                isBusy: false,
                tableItems: [],
                tableTotal: 0,
                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                // files: [],
                uploadedFies: []
            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },

        },
        methods: {
            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/' + this.MODULE_PREFIX + '_' + this.PREFIX, {
                    params: {
                        contact_id: this.moduleItem.id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                    }
                }, function (resp) {
                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/' + id, {params: {}}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },
            changeAcception($event, item) {

                this.async('put', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + '/accept_rodo/' + item.id, {
                    contact_id: this.module_id,
                    is_accepted: $event ? 1 : 0
                }, function (res) {
                    this.refreshDataTable();
                });
            },
            uploadFiles(files, rodo_id) {

                if (files.length > 0) {

                    let formData = new FormData();

                    formData.append('contact_id', this.module_id);
                    for (let i = 0; i < files.length; i++) {
                        formData.append('document[]', files[i]);
                    }
                    formData.append('ac_id', rodo_id);

                    this.async('post', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + '/add_document', formData, function (res) {
                        // this.files = [];
                        this.$refs['file-input-'+rodo_id].clear();
                        this.refreshDataTable();
                    }, false, {headers: {'Content-Type': 'multipart/form-data'}});
                }

            },


        },
        created() {
            let self = this;
            this.module_id = this.$router.currentRoute.params.id;
            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                if (obj.key == 'is_accepted' && !self.$can('edit', 'contact.tab_' + self.PREFIX)) {
                    return false;
                }
                return true;

            });
            this.refreshDataTable();
        },
    }
</script>
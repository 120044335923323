<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'contacts' }">{{$t('label_potential_clients')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t(curPageLabel)}}</b-breadcrumb-item>
            </div>
        </div>

        <!--actions panel-->
        <b-card no-body>
            <b-card-body class="tab-tools">

                <div class="text-uppercase mr-1 mb-1"><strong>{{moduleItem.first_name + ' ' + moduleItem.last_name}}</strong></div>

                <b-button v-if="$can('edit', 'contact.tab_details')" variant="outline-primary" :editedItem="moduleItem" @click="isSidebarOpen=true" class="mr-1">
                    <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                    />
                    <span>{{$t('label_edit')}}</span>
                </b-button>
                <b-button v-if="$can('edit', 'contact.tab_meetings')" variant="outline-primary" class="mr-1" @click="editedMeeting = false; $bvModal.show(MEETING_PREFIX + '-modal')">
                    <feather-icon
                            icon="MessageCircleIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_arrange_meeting')}}</span>
                </b-button>
                <b-button   v-if="$can('edit', 'agreement.tab_details')" variant="outline-primary"  :to="{name: 'agreement_add', params: { contact_id: moduleItem.id }}" class="mr-1">
                    <feather-icon
                            icon="ExternalLinkIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_convert_to_agreement')}}</span>
                </b-button>
                <b-button   v-if="$can('view', 'contact.tab_notes')" variant="outline-primary" class="mr-1" @click="editedNote = false;$bvModal.show(NOTE_PREFIX + '-modal')">
                    <feather-icon
                            icon="BookmarkIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_add_note')}}</span>
                </b-button>
                <b-button variant="outline-primary" v-if="$can('edit', MODULE_PREFIX+'.tab_sms')" class="" @click="editedSms = false;$bvModal.show(SMS_PREFIX + '-modal')">
                    <feather-icon
                            icon="BookmarkIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_send_sms')}}</span>
                </b-button>
            </b-card-body>
        </b-card>


        <b-card no-body>

            <b-card-body>
                <b-tabs content-class="mt-1">

                    <b-tab
                            class="pt-2"
                            v-if="$can('view', 'contact.tab_details')"
                           @click="curPageLabel = $t('label_informations')"
                            :title="$t('label_informations')"
                            lazy
                    >

                        <tab-details :item-data="moduleItem" ></tab-details>

                    </b-tab>


                    <b-tab    :ref="NOTE_PREFIX + '_TAB'"
                            v-if="$can('view', 'contact.tab_notes')"
                            @click="curPageLabel = $t('label_notes')"
                            :title="$t('label_notes')"
                            lazy
                    >
                        <tab-notes @edit-item="editedNote = $event" :ref="NOTE_PREFIX + '_CONTENT_TAB'"
                                   :module-item="moduleItem" ></tab-notes>

                    </b-tab>

                    <b-tab
                            :ref="MEETING_PREFIX + '_TAB'"
                            v-if="$can('view', 'contact.tab_meetings')"
                            @click="curPageLabel = $t('label_meetings')"
                           :title="$t('label_meetings')"
                            lazy
                    >

                        <tab-meetings :ref="MEETING_PREFIX + '_CONTENT_TAB'" @edit-item="editedMeeting = $event;"
                                      :module-item="moduleItem" ></tab-meetings>

                    </b-tab>
                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_sms')"
                            @click="curPageLabel = $t('label_sms')"
                            :title="$t('label_sms')"
                            lazy
                    >
                        <tab-sms
                                module-type="contact"
                                :module-item="moduleItem"
                                :ref="SMS_PREFIX + '_CONTENT_TAB'"
                                @edit-item="editedSms = $event;"
                        ></tab-sms>

                    </b-tab>
                    <b-tab class="pt-2"
                            v-if="$can('view', 'contact.tab_rodo')"
                            @click="curPageLabel = $t('label_rodo')"
                            :title="$t('label_rodo')"
                            lazy
                    >

                        <tab-rodo :module-item="moduleItem" ></tab-rodo>

                    </b-tab>

                </b-tabs>
            </b-card-body>
        </b-card>

<!--//modals-->
        <item-processing-modal :edited-item="moduleItem" :is-sidebar-open.sync="isSidebarOpen" @item-updated="fetchModuleItem($event.id)"></item-processing-modal>
        <process-note-modal
                :edited-item="editedNote"
                @item-edited="$refs[NOTE_PREFIX + '_CONTENT_TAB'] ? $refs[NOTE_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-added="$refs[NOTE_PREFIX + '_CONTENT_TAB'] ? $refs[NOTE_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-note-modal>
        <process-meeting-modal
                :edited-item="editedMeeting"
                @item-added="$refs[MEETING_PREFIX + '_CONTENT_TAB'] ? $refs[MEETING_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false; $refs[MEETING_PREFIX + '_TAB'].activate()"
                @item-edited="$refs[MEETING_PREFIX + '_CONTENT_TAB'] ? $refs[MEETING_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-meeting-modal>
        <process-sms-modal
                module-type="contact"
                :module-item="moduleItem"
                :edited-item="editedSms"
                @item-edited="$refs[SMS_PREFIX + '_CONTENT_TAB'] ? $refs[SMS_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-added="$refs[SMS_PREFIX + '_CONTENT_TAB'] ? $refs[SMS_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-sms-modal>
    </div>
</template>

<script>


    import {
        BBreadcrumb, BCardText, BCard, BCardHeader, BCardBody, BCol, BRow,
        BTabs, BTab, BAlert, BButton,

    } from 'bootstrap-vue'

    import itemProcessingModal from './modals/itemProcessingModal'
    import processMeetingModal from './modals/processMeetingModal'
    import processNoteModal from './modals/processNoteModal'
    import processSmsModal from '../agreement/modals/processSmsModal'

    import tabDetails from './tabs/tabDetails'
    import tabMeetings from './tabs/tabMeetings'
    import tabNotes from './tabs/tabNotes'
    import tabRodo from './tabs/tabRodo'
    import tabSms from '../agreement/tabs/tabSms'

    import { CONTACT_PREFIX as MODULE_PREFIX, NOTE_PREFIX, MEETING_PREFIX, RODO_PREFIX, SMS_PREFIX} from './moduleHelper'

    export default {

        components: {
            BBreadcrumb,
            BCardText,
            BCard, BCardHeader, BCardBody, BRow, BCol,
            BTabs, BTab, BAlert, BButton,

            processMeetingModal,
            itemProcessingModal,
            processNoteModal,
            processSmsModal,

            tabDetails,
            tabMeetings,
            tabNotes,
            tabRodo,
            tabSms
        },
        data() {
            return {
                MODULE_PREFIX,
                NOTE_PREFIX,
                MEETING_PREFIX,
                RODO_PREFIX,
                SMS_PREFIX,

                curPageLabel: 'label_informations',

                moduleItem: {},

                isSidebarOpen: false,

                editedMeeting:false,
                editedNote:false,
                editedSms:false,
            }
        },

        created() {

            let module_item_id = this.$router.currentRoute.params.id;

            this.fetchModuleItem(module_item_id);

        },

        methods: {
            fetchModuleItem(id){
                let self = this;
                    this.async('get', '/'+MODULE_PREFIX+'/' + id, {}, function (resp) {
                        this.moduleItem = resp.data;

                        let alias_route_param = this.$router.currentRoute.params.alias;
                        if(alias_route_param){
                            this.$nextTick(function(){self.$refs[alias_route_param+'_TAB'].activate();});
                        }
                    });
            },

        },

    }
</script>


<template>

    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_edit_note'):$t('label_add_note')}}
        </template>
        <template #default="{ hide }">


        <validation-observer
                tag="form"
                class="p-1"
                #default="{handleSubmit, reset, invalid}"
                :ref="PREFIX + '_FORM'"

        >

                <input type="hidden" name="id" id="n_id" v-model="itemData.id">
                <input type="hidden" name="contact_id" v-model="itemData.contact_id">
                <validation-provider
                        #default="validationProps"
                        :name="$t('label_subject')"
                        rules="required"
                        slim
                        mode="aggressive"
                >
                    <b-form-group
                            :label="$t('label_subject')"
                            label-for="n-title"
                    >
                        <b-form-input
                                id="n-title"
                                v-model="itemData.title"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_subject')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_description')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_description')"
                            label-for="n-description"
                    >
                        <b-form-textarea
                                id="n-description"
                                :state="getValidationState(validationProps)"
                                v-model="itemData.description"
                        ></b-form-textarea>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

            <validation-provider

                            #default="validationProps"
                            :name="$t('label_date')"
                            rules="required"
                            class="mr-1 flex-grow-1"
                            slim
                            bails
                            detectInput
                            :immediate="false"
                    >
                        <b-form-group
                                :label="$t('label_date')"

                        >

                                <custom-date-picker
                                        :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                        :locale="$store.state.appConfig.locale"
                                        :placeholder="$t('label_DD-MM-YYYY')"

                                        :value="itemData.deadline_at"
                                        input-format="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        @input="itemData.deadline_at = $event"
                                >
                                    <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                        <div slot="label">
                                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                        </div>
                                </custom-date-picker>


                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

            <div class="d-flex justify-content-end mt-2">
                <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_close')}}</button>

                <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                        class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_save'):$t('label_submit')}}</button>
            </div>

        </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
         BFormTextarea,
        BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend,
    } from 'bootstrap-vue'

    import { CONTACT_PREFIX as MODULE_PREFIX,  NOTE_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            // vSelect
        },
        props:['editedItem'],
        data() {
            return {


                MODULE_PREFIX,
                PREFIX,

                action: 'adding',

                blankItemData: {
                    id:0,
                    title: '',
                    description: '',
                    deadline_at: this.$moment().format('DD-MM-YYYY'),
                    contact_id: 0
                },

                itemData: {}

            }
        },

        methods: {
            initData(){
                let editedItem = this.editedItem;
                if(editedItem){
                    this.action = 'editing';
                    editedItem = Object.assign({},editedItem);
                    editedItem.deadline_at = formatDate(editedItem.deadline_at, 'DD/MM/YYYY', 'unix');
                    this.itemData = editedItem;
                } else {
                    this.action = 'adding';
                    this.itemData =  Object.assign({},this.blankItemData)
                }
            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){
                if(this.action == 'adding') {
                    this.async('post', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s', this.itemData, function(resp){
                        this.$emit('item-added',{});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                } else {
                    this.async('put', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s/'+this.itemData.id, this.itemData, function(resp){ //this.itemData.contact_id
                        this.$emit('item-edited',{});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                }

            }
        },


        mounted (){
           this.blankItemData.contact_id = this.$router.currentRoute.params.id;
        }
    }
</script>